<template>
  <div class="container">
    <h2>Relatorio de Marcas dos Produtos</h2>
    <h5>
      Total de Produtos: <strong>{{ report.length }}</strong>
    </h5>
    <b-table
      striped
      hover
      :items="report"
      :fields="fields"
      :filter="filter"
      thead-class="text-blue"
      class="table"
    >
      <template #cell(sku)="data">
        <b-link :href="`/product/${data.item.id_backoffice}`">{{
          data.item.sku[0]
        }}</b-link>
        <p v-if="data.item.sku.length > 1">
          (
          <a v-for="prod in data.item.sku" :key="prod">
            <a v-if="prod != data.item.sku[0]">
              {{ prod }}
            </a>
          </a>
          )
        </p>
      </template>
      <template #cell(ean)="data">
        <p v-for="prod in data.item.ean" :key="prod">
          {{ prod }}
        </p>
      </template>
      <template #cell(brand)="data">
        <button
          class="btn btn-primary"
          v-b-modal.modal-marca
          @click="
            productData(
              data.item.name,
              data.item.sku[0],
              data.item.id_backoffice
            )
          "
        >
          Adiconar Marca
        </button>
      </template>
    </b-table>
    <b-modal
      ref="modal_marca"
      id="modal-marca"
      :title="'Adicionar Marca'"
      hide-footer
    >
      <form v-on:submit.prevent="addBrandToProduct()">
        <div class="form-group">
          <p>
            SKU: <b>{{ productSku }}</b>
          </p>
          <p>
            <b>{{ productName }}</b>
          </p>
          <label for="addressInput">Marca:</label>
          <v-select :options="allBrands" v-model="selectedBrand" label="name">
            <template slot="option" slot-scope="option">
              {{ option.name }}</template
            >
          </v-select>
        </div>
        <div class="selectBtn">
          <button type="submit" class="btn btn-primary">Adicionar Marca</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: function () {
    return {
      report: [],
      filter: null,
      productName: "",
      productIdBackoffice: "",
      productSku: "",
      fields: [
        {
          key: "sku",
          label: "SKU/CNP",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "ean",
          label: "EAN",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "name",
          label: "Nome",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
        {
          key: "brand",
          label: "Adicionar Marca",
          tdClass: "align-middle",
          thStyle:
            "font-size: 18px ; background-color:rgb(255, 147, 147);vertical-align: middle",
        },
      ],
      allBrands: [],
      selectedBrand: "",
    };
  },
  methods: {
    async brandReport() {
      try {
        await this.$store.dispatch("getMissingProductsBrands");
        console.log(this.getMissingBrands);
        this.report = this.getMissingBrands;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async addBrandToProduct() {
      try {
        await this.$store.dispatch("updateProductBrand", {
          id: this.productIdBackoffice,
          brand: this.selectedBrand.id,
        });
        this.$swal({
          toast: true,
          icon: "success",
          title: "Marca adicionada ao produto com sucesso",
          showConfirmButton: false,
          timer: 3000,
          position: "top-end",
        });
        this.$refs["modal_marca"].hide();
        await this.brandReport()
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async brands() {
      try {
        await this.$store.dispatch("getAllBrands");
        console.log(this.getBrands);
        this.allBrands = this.getBrands;
      } catch (err) {
        console.log(err);
        alert(err);
      }
    },
    async productData(name, sku, id) {
      this.productName = name;
      this.productIdBackoffice = id;
      this.productSku = sku;
    },
    routeToProduct(productId) {
      this.$router.push(`product/${productId}`);
    },
    // splitDate(date) {
    //   let split = date.split('T');
    //   return split[0].substring(0, split[0].length - 3);
    // },
  },
  async created() {
    this.brandReport();
    this.brands();
  },
  computed: {
    ...mapGetters(["getMissingBrands"]),
    ...mapGetters(["getBrands"]),
  },
};
</script>

<style scoped>
.table {
  text-align: center;
}
.buttons {
  text-align: center;
  margin: 15px 0px 15px 0px;
}
h2 {
  text-align: center;
  margin-top: 5px;
}
h5 {
  text-align: center;
}
/* .btn {
  margin: 0px 5px 0px 5px;
} */
</style>